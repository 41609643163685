import React, { useState }     from 'react';
import { navigate }            from 'gatsby';
import { Col, Container, Row } from 'react-grid-system';
import { injectIntl }          from 'react-intl';
import { useFormState }        from 'react-use-form-state';
import {
  Button,
  Input,
  FormLabel,
  Textarea
}                              from '@chakra-ui/react';

import routes from '@interness/web-core/config/routes';

import * as S from './styles';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const ContactForm = (props) => {

  const intl = props.intl.formatMessage;

  const [loading, setLoading] = useState(false);
  const [formState, { email, label, text, textarea }] = useFormState({}, {
    withIds: true,
    validateOnBlur: true,
  });

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true)

    fetch(`${process.env.GATSBY_ORDER_API_ENDPOINT}/atacama/contact`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        hostname: document.location.host,
        ...formState.values
      })
    })
      .then((res) => {
        if (res.status === 500 || res.status === 404) {
          alert('E-Mail konnte nicht versendet werden. Bitte versuchen Sie es später erneut.')
          setLoading(false);
        } else if (res.status === 200) {
          setLoading(false);
          navigate(routes.thanks[props.intl.locale], {
            state: formState.values,
          });
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        alert('E-Mail konnte nicht versendet werden. Bitte versuchen Sie es später erneut.');
      });
  };

  return (
    <S.Form
      name="contact"
      onSubmit={handleSubmit}>
      <Container fluid>
        <Row>
          <Col md={6}>
            <FormLabel {...label('name')}>{intl({ id: 'components.netlifyContactForm.name' })}</FormLabel>
            <Input colorScheme="base" {...text('name')}
                   placeholder={intl({ id: 'components.netlifyContactForm.namePlaceholder' })} variant="filled"
                   required/>
          </Col>
          <Col md={6}>
            <FormLabel {...label('email')}>{intl({ id: 'components.netlifyContactForm.email' })}</FormLabel>
            <Input {...email('email')} placeholder={intl({ id: 'components.netlifyContactForm.emailPlaceholder' })}
                   required variant="filled"/>
          </Col>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Col md={12}>
            <FormLabel {...label('Telefonnummer')}>Telefonnummer</FormLabel>
            <Input {...text('Telefonnummer')}
                   required variant="filled"/>
          </Col>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Col md={12}>
            <FormLabel {...label('message')}>{intl({ id: 'components.netlifyContactForm.message' })}</FormLabel>
            <Textarea {...textarea('message')} variant="filled"
                      placeholder={intl({ id: 'components.netlifyContactForm.messagePlaceholder' })}
                      required/>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ textAlign: 'right' }}>
              <Button mt={6} colorScheme="brand" type="submit"
                      isDisabled={loading} isLoading={loading}>
                {intl({ id: 'components.netlifyContactForm.submit' })}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </S.Form>
  )
}

export default injectIntl(ContactForm);
